import { Button, Carousel, Col, Row } from 'react-bootstrap';
import '../styles/css/Home.css';
import MoreContentArrow from './components/MoreContentArrow';
import olbarLogo from '../assets/olbar-logo-2.png';
import COMIMDA_1_IMG from '../assets/nuevas/IMG_1530.jpg';
import COMIMDA_2_IMG from '../assets/nuevas/IMG_1511.jpg';

const Home = () => {
    return (
        <div id="home" className="position-relative">
            <div
                style={{ zIndex: 1 }}
                className="position-absolute top-0 left-0 h-100 w-100 d-flex flex-column align-items-center justify-content-center"
            >
                <Row className="justify-content-center w-100">
                    <Col md="4" xs="11" className="text-center">
                        <img src={olbarLogo} alt="" width="100%" />
                    </Col>
                </Row>
                <Row className="justify-content-center w-100">
                    <Col xl="4" lg="6" md="10" sm="10" xs="12" className="text-center">
                        <div className="border border-3 px-5 py-3 shadow-lg" style={{ background: "#00000059" }}>
                            <h1 className="display-6 text-white">ENCUÉNTRANOS en</h1>
                            <div className="py-2 px-3 bg-black text-white shadow-sm">
                                <i className="fas fa-map-marker-alt me-3" aria-hidden="true"></i>
                                <span className="fw-medium">Calle de Belvis 3, Chamberi - Madrid</span>
                            </div>
                        </div>
                    </Col>
                </Row>

                <div className="text-center my-5">
                    <Button
                        className="shadow-lg"
                        href="carta.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        NUESTRA CARTA <i className="fa-regular fa-file-lines ms-2"></i>
                    </Button>
                </div>
            </div>
            <Carousel
                fade
                style={{ zIndex: 0 }}
                className="vh-100 overflow-hidden shadow-lg"
                controls={false}
                indicators={false}
            >
                <Carousel.Item>
                    <img src={COMIMDA_1_IMG} alt="..." className="carousel-img" />
                </Carousel.Item>
                <Carousel.Item>
                    <img src={COMIMDA_2_IMG} alt="..." className="carousel-img" />
                </Carousel.Item>
            </Carousel>
            <MoreContentArrow />
        </div>
    );
};

export default Home;